import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

export function getCallNumber() {
  const cityStore = useCities()
  const mainStore = useMainStore()
  const currentCity = computed(() => cityStore.getCity)
  const street = computed(() => cityStore.getDataStreet)
  const houseId = computed(() => cityStore.getHouseId)
  const house = computed(() => cityStore.getHouse)
  const utmArr = computed(() => mainStore.getUtmArr)
  const trackingId = ref()
  const clientIp = computed(() => mainStore.getClientIp)
  const $route = useRoute()
  const abTestCookie = useCookie('ab_test')

  const callNumber = ref({
    call_number: '',
    display_number: '8 800 ',
  })
  const ctx = useNuxtApp()
  const isMobile = computed(() => !ctx.$device.isDesktop)
  const routeChanged = ref(false)
  const userActivity = ref(true)
  const provider = computed(() => mainStore.getDomainConfig)
  const segmentationActive = computed(() => mainStore.getSegmentationActive)
  const hideNumber = computed(() => mainStore.getHideNumber)
  const diProvider = computed(() => mainStore.getDiProvider)
  const certainty = computed(() => cityStore.getCertainty)

  const gacid = () => {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)')
    const raw = match ? decodeURIComponent(match[1]) : null
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/)
    }
    const gacid = match ? match[1] : null
    if (gacid) {
      return gacid
    }
  }

  const yaid = () => {
    const match = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)')
    return match ? decodeURIComponent(match[1]) : ''
  }

  async function getPhoneNumber(btn, tariff?, second?) {
    if (
      callNumber.value.call_number
      && typeof window !== 'undefined'
      && !second
    ) {
      window.open('tel:' + callNumber.value.call_number, '_self')
      ctx.$event('gtmSendCall', { action: 'callattempt_sales', label: '' })
    }
    else if (
      isMobile.value
      && btn
      && typeof window !== 'undefined'
      && !second
    ) {
      routeChanged.value = false
      await getNumber(tariff)
      nextTick(() => {
        window.open('tel:' + callNumber.value.call_number, '_self')
        ctx.$event('gtmSendCall', { action: 'callattempt_sales', label: '' })
      })
      routeChanged.value = true
    }
    else {
      routeChanged.value = false
      await getNumber(tariff)
    }
  }

  let interval
  let timeTariff
  let errCounter = 0

  const getNumber = async (tariff) => {
    const partnerId = useCookie('partner_id')
    if (gacid() && errCounter < 10 && !segmentationActive.value && !hideNumber.value) {
      try {
        const res = await $fetch(`${mainStore.apiDomain}/calls/tracking/`, {
          method: 'POST',

          body: {
            yandex_id: yaid() || '',
            google_id: gacid() || '',
            page_url:
              ('https://' + mainStore.domain) + $route.fullPath,
            site_type: provider.value ? undefined : diProvider.value ? 'provider' : 'aggregator',
            user_activity: userActivity.value,
            fias_id: currentCity.value?.fias_id,
            tracking_id: trackingId.value,
            user_ip: clientIp.value,
            partner_id: partnerId.value,
            address: {
              city_fias_id: certainty.value === 'FALLBACK' ? undefined : currentCity.value?.fias_id,
              street_fias_id: street.value?.fias_id,
              house_id: houseId.value || undefined,
              house_dadata: house.value?.dadata || {},
            },
            utm: utmArr.value,
            tariff: {
              ...tariff,
            },
            extra: {
              site_version: abTestCookie.value || undefined,
            },
          },
        })
        if (res.call_number) {
          callNumber.value = {
            call_number: res.call_number,
            display_number: res.number_display,
          }
        }
        else {
          const phone = computed(() => mainStore.getCallNumber)
          if (!phone.value) await mainStore.fetchPhoneNumber()
          nextTick(() => {
            callNumber.value = {
              call_number: phone.value!.connect!.call_number,
              display_number: phone.value!.connect!.display_number,
            }
          })
        }
        trackingId.value = res.tracking_id
        userActivity.value = false
        checkUserActivity()
        errCounter = 0
        interval = setTimeout(() => {
          if (routeChanged.value) clearTimeout(interval)
          else getNumber(tariff)
        }, res.interval * 1000)
      }
      catch (e) {
        errCounter++
        timeTariff = tariff
        checkReturnActivity()
        const phone = computed(() => mainStore.getCallNumber)
        nextTick(() => {
          callNumber.value = {
            call_number: phone.value!.connect!.call_number,
            display_number: phone.value!.connect!.display_number,
          }
          return callNumber.value
        })
      }
      return callNumber.value
    }
    else {
      const phone = computed(() => mainStore.getCallNumber)
      if (!phone.value) await mainStore.fetchPhoneNumber()
      nextTick(() => {
        callNumber.value = {
          call_number: phone.value!.connect!.call_number,
          display_number: phone.value!.connect!.display_number,
        }
        return callNumber.value
      })
    }
  }

  const stopCheckUserActivity = () => {
    userActivity.value = true
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', stopCheckUserActivity)
      window.removeEventListener('mousemove', stopCheckUserActivity)
      window.removeEventListener('click', stopCheckUserActivity)
      window.removeEventListener('touchstart', stopCheckUserActivity)
    }
  }

  const stopCheckReturnActivity = () => {
    getNumber(timeTariff)
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', stopCheckReturnActivity)
      window.removeEventListener('mousemove', stopCheckReturnActivity)
      window.removeEventListener('click', stopCheckReturnActivity)
      window.removeEventListener('touchstart', stopCheckReturnActivity)
    }
  }

  const stopGetCall = () => {
    clearTimeout(interval)
    callNumber.value.call_number = ''
    callNumber.value.display_number = '8 800 '
    routeChanged.value = true
    if (typeof window !== 'undefined') {
      stopCheckUserActivity()
    }
  }

  const checkUserActivity = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', stopCheckUserActivity)
      window.addEventListener('mousemove', stopCheckUserActivity)
      window.addEventListener('click', stopCheckUserActivity)
      window.addEventListener('touchstart', stopCheckUserActivity)
    }
  }

  const checkReturnActivity = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', stopCheckReturnActivity)
      window.addEventListener('mousemove', stopCheckReturnActivity)
      window.addEventListener('click', stopCheckReturnActivity)
      window.addEventListener('touchstart', stopCheckReturnActivity)
    }
  }

  onBeforeUnmount(() => {
    routeChanged.value = true
    if (typeof window !== 'undefined') {
      stopCheckUserActivity()
    }
  })

  watch(
    () => $route.path,
    () => {
      stopGetCall()
    },
  )

  return {
    callNumber,
    getPhoneNumber,
    getNumber,
    stopCheckUserActivity,
    stopGetCall,
  }
}
